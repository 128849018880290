import React from 'react';
import { Router } from '@reach/router';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import UserReport from './UserReport';

const UserReportWrapper:React.FC = () => (
  <AdminProvider>
    <Router basepath="/admin/performance/user-report">
      <UserReport path="/:userId" />
      <UserReport default />
    </Router>
  </AdminProvider>
);

export default UserReportWrapper;
