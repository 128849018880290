// extracted by mini-css-extract-plugin
export var alignmentContainer = "UserReport-module--alignmentContainer--1-JB2";
export var headingContainer = "UserReport-module--headingContainer--2Vzo3";
export var userReportContainer = "UserReport-module--userReportContainer--3q773";
export var backArrowButton = "UserReport-module--backArrowButton--3QkE0";
export var pageTitle = "UserReport-module--pageTitle--2YTnQ";
export var durationFilterExport = "UserReport-module--durationFilterExport--H4bdX";
export var filterTextExport = "UserReport-module--filterTextExport--2Q-8G";
export var userName = "UserReport-module--userName--27JqY";
export var userEmail = "UserReport-module--userEmail--oXY8A";
export var titleValue = "UserReport-module--titleValue--1d4VA";
export var summaryContainer = "UserReport-module--summaryContainer--2_iG9";
export var title = "UserReport-module--title--bFZBa";
export var summaryTitle = "UserReport-module--summaryTitle--JyPJ_";
export var summaryValue = "UserReport-module--summaryValue--2vQh9";
export var tableTitle = "UserReport-module--tableTitle--3N5JD";
export var enrollmentHead = "UserReport-module--enrollmentHead--EIpjc";
export var downloadLink = "UserReport-module--downloadLink--2FJqm";
export var nameButton = "UserReport-module--nameButton--2c74o";
export var table = "UserReport-module--table--2FcOv";