import React from 'react';
import Authenticate from '../../../components/Identity/Authenticate';
import UserReportWrapper from
  '../../../components/Admin/Performance/UserReportWrapper';
import * as styles from '../../../styles/admin/Account.module.scss';

function UserReportView() {
  return (
    <div className={styles.accountPageWrapper}>
      <UserReportWrapper />
    </div>
  );
}
export default Authenticate(UserReportView);
