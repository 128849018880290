/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';
import { Table, Progress } from 'antd';
import { CSVLink } from 'react-csv';
import { DataStore } from 'aws-amplify';
import { RouteComponentProps } from '@reach/router';
import { Course, Group } from '../../../models/index';
import { getUserById } from '../../DataStore/UserService';
import * as styles from '../../../styles/admin/UserReport.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import { fetchMetrics } from '../Services/MetricsApiService';
import { getGroupMember } from '../../GraphQL/Services';
import {
  CourseEnrollment, CourseEnrollmentReportResponse, CourseSummary,
  EnrollmentReportAttributes, IUserData, UserReportResponse,
} from '../../../types/userReport';

interface Props extends RouteComponentProps {
  userId?: string,
}

const UserReport:React.FC<Props> = ({ userId }) => {
  const { companyInfo } = useContext(AdminContext);
  const [userData, setUserData] = useState({
    user: {} as IUserData,
    groups: [] as Group[],
  });
  const [tableData, setTableData] = useState<CourseEnrollment[]>([]);
  const [loader, setLoader] = useState(true);
  const [courseSummary, setCourseSummary] = useState<CourseSummary>({} as CourseSummary);

  const fetchEnrolledCourses = async (enrollmentsReport:EnrollmentReportAttributes[]) => {
    const enrollments:CourseEnrollment[] = [];
    if (enrollmentsReport?.length > 0) {
      enrollmentsReport.forEach((enrollment:EnrollmentReportAttributes) => {
        const attributes = enrollment?.attributes;
        const totalLessons = attributes?.videoTasks;
        const lessonsWatched = attributes?.videoTasksCompleted;
        const totalAssignments = attributes?.assignmentTasks
         + attributes?.finalAssignmentTasks;
        const assignmentsCompleted = attributes?.assignmentTasksCompleted
         + attributes?.finalAssignmentTasksCompleted;
        const totalTasksCompleted = lessonsWatched + assignmentsCompleted;
        const totalTasks = totalLessons + totalAssignments;
        const completion = totalTasks
          ? Math.round((totalTasksCompleted / totalTasks) * 100) : 0;
        enrollments.push({
          key: enrollment?.key,
          courseName: enrollment?.course?.courseName,
          courseId: enrollment?.key,
          lessons: `${lessonsWatched}/${totalLessons}`,
          assignments: `${assignmentsCompleted}/${totalAssignments}`,
          completion,
        });
      });
    }
    setTableData(enrollments);
    setLoader(false);
  };

  const fetchRegistrationByCourseId = async (courseId:string) => {
    try {
      const registrationWithCourse = await DataStore.query(Course, courseId);
      return registrationWithCourse;
    } catch (error) {
      return null;
    }
  };

  const mergeEnrollmentsReport = async (response:CourseEnrollmentReportResponse) => {
    if (response?.data?.length > 0) {
      const enrollmentsReport:EnrollmentReportAttributes[] = [];
      const dataMap = new Map();
      const includedData = response?.included?.[0];
      response?.data.forEach((data) => {
        const courseId = data?.relationships?.course?.data?.id;
        dataMap.set(courseId, data);
      });
      const courseIds = response?.data.map((data) => (
        data?.relationships?.course?.data?.id
      ));
      const promises = courseIds.map((id:string) => (
        fetchRegistrationByCourseId(id)
      ));
      const courseDetails = await Promise.all(promises);
      courseDetails.forEach((course) => {
        const courseId = course?.id;
        const data = dataMap.get(courseId);
        if (data && courseId) {
          enrollmentsReport.push({
            key: courseId,
            attributes: data?.attributes,
            lastActive: includedData?.attributes?.lastActive,
            userName: includedData?.attributes?.name,
            course,
          });
        }
      });
      return enrollmentsReport;
    }
    return [];
  };

  const mergeUserReportResponse = (response:UserReportResponse) => {
    const attributes = response?.data?.attributes;
    const includedData = response?.included?.[0];
    const lastActive = includedData?.attributes?.lastActive;
    const formattedLastActiveDate = lastActive ? dayjs(lastActive).format('DD/MM/YYYY') : '';
    return {
      attributes,
      name: includedData?.attributes?.name,
      lastActive: formattedLastActiveDate,
      userId: includedData?.id,
    };
  };

  const fetchUserEnrollmentsReport = async () => {
    const enterpriseId = companyInfo?.id;
    const path = '/courseregistrationstats';
    try {
      const response:CourseEnrollmentReportResponse = await fetchMetrics(path, {
        enterpriseId,
        userId,
      });
      const mappedResponse = await mergeEnrollmentsReport(response);
      fetchEnrolledCourses(mappedResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserReport = async () => {
    const enterpriseId = companyInfo?.id;
    const path = `/userperformancestats/${userId}`;
    try {
      const response = await fetchMetrics(path, {
        enterpriseId,
      });
      const mergedResponse = mergeUserReportResponse(response);
      setCourseSummary(mergedResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGroupInfo = async (userDetails:IUserData) => {
    try {
      if (userDetails?.groupMemberships?.items?.length > 0) {
        const promises = userDetails?.groupMemberships?.items.map((item) => {
          const groupId = item?.groupID;
          const memberId = item?.memberID;
          return getGroupMember(groupId, memberId);
        });
        const response = await Promise.all(promises);
        const groups:Group[] = response.map((res) => (
          res.group
        ));
        setUserData({
          groups,
          user: userDetails,
        });
      } else {
        setUserData({
          groups: [],
          user: userDetails,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const userDetails = await getUserById(userId);
      fetchGroupInfo(userDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToPerformanceTab = () => {
    navigate('/admin/performance/');
  };

  useEffect(() => {
    if (companyInfo?.id && userId) {
      fetchUserReport();
      fetchUserEnrollmentsReport();
      fetchUserInfo();
    }
  }, [companyInfo]);

  const enrollmentColumns:any = [
    {
      title: <span className={styles.tableTitle}>Course Name</span>,
      dataIndex: 'courseName',
      key: 'courseName',
      render: (text:string) => (
        <button type="button" style={{ cursor: 'default' }} className={styles.nameButton}>
          {text}
        </button>
      ),
    },
    {
      title: <span className={styles.tableTitle}>Lessons</span>,
      dataIndex: 'lessons',
      key: 'lessons',
    },
    {
      title: <span className={styles.tableTitle}>Assignments</span>,
      dataIndex: 'assignments',
      key: 'assignments',
    },
    {
      title: <span className={styles.tableTitle}>Completion</span>,
      dataIndex: 'completion',
      key: 'completion',
      defaultSortOrder: 'descend',
      sorter: (a:CourseEnrollment, b:CourseEnrollment) => (
        Number(a.completion) - Number(b.completion)),
      render: (value:number) => (
        <Progress
          percent={Math.ceil(value)}
          trailColor="#f0f4f7"
          strokeColor="#a4ede2"
          width={150}
        />
      ),
    },
  ];

  const headers = enrollmentColumns.map((
    column:any,
  ) => ({ label: column.key, key: column.dataIndex }));

  return (
    <div className={styles.userReportContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.alignmentContainer}>
          <button
            type="submit"
            onClick={navigateToPerformanceTab}
            className={`library-shaw-icon-new ${styles.backArrowButton}`}
          >
            <i className="icon-new-Left-Arrow-6" />
          </button>
          <div className={styles.nameConatiner}>
            <div className={styles.pageTitle} style={{ textAlign: 'left' }}>Learner Report</div>
            <div className={styles.userName}>{userData?.user?.name}</div>
            <div className={styles.userEmail}>{userData?.user?.email}</div>
          </div>
        </div>
        <div>
          <div className={styles.pageTitle}>Group</div>
          <div className={styles.titleValue}>
            {userData?.groups?.map((group, index) => (
              <React.Fragment key={group?.id}>
                <span>{group?.name}</span>
                {userData?.groups?.length > 1
                && userData?.groups?.length - 1 !== index
                && <span>,</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div>
          <div className={styles.pageTitle}>Role</div>
          <div className={styles.titleValue}>{userData?.user?.id && (userData?.user?.roles?.[0] || 'LEARNER')}</div>
        </div>
      </div>
      <div className={styles.summaryContainer}>
        <div>
          <div className={styles.title}>Summary</div>
          <div className={styles.summaryTitle} style={{ textAlign: 'left' }}>
            <span className={styles.summaryTitle}>Last active: </span>
            {courseSummary?.lastActive}
          </div>
        </div>
        <div>
          <div className={styles.summaryValue}>{courseSummary?.attributes?.coursesEnrolled}</div>
          <div className={styles.summaryTitle}>Total course enrollments</div>
        </div>
        <div>
          <div className={styles.summaryValue}>
            {courseSummary?.attributes?.contentConsumptionHours}
            <span style={{ fontSize: '16px' }}>hrs</span>
          </div>
          <div className={styles.summaryTitle}>Lessons watched</div>
        </div>
        <div>
          <div className={styles.summaryValue}>
            {courseSummary?.attributes?.tasksCompleted}
          </div>
          <div className={styles.summaryTitle}>Assignments completed</div>
        </div>
      </div>
      <div className={styles.tableTitle}>
        <div className={styles.enrollmentHead}>Enrollments</div>
        <CSVLink
          className={styles.downloadLink}
          data={tableData}
          headers={headers}
          filename="User Report.csv"
        >
          Download
        </CSVLink>
      </div>
      <Table
        bordered={false}
        dataSource={tableData}
        columns={enrollmentColumns}
        pagination={false}
        className={styles.table}
        loading={loader}
      />
    </div>
  );
};

export default UserReport;
